module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kinetics Ventilation Equipment","short_name":"KVE AE","description":"Providing high quality and efficient ventilation systems and solutions since 2005.","start_url":"/","background_color":"#ffffff","theme_color":"#751af7","icon":"src/images/logo-crop.png","display":"standalone"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
